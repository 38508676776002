import React, { useEffect, useState, useRef } from "react";
import yaml from "js-yaml";
import { Config } from "../types/Config";
import '../../css/Config.css';

// Configuration par défaut si l'import YAML échoue
const defaultConfig: Config = {
  info: {
    client: "default",
    keycloack_role: [],
  },
  parameters: {
    maxZoom: 18,
    minZoom: 4,
    zoom: 6,
    centerCross: false,
    mapCenter: [0, 0],
    slider_ref_index: 3000,
    eodyn_ref_index: 4000,
    basic_ref_index: 1000,
    default_ref_index: 2000,
    start_time: "",
    maxExtent: [-175, -85, 175, 85],
    loading_time: 3000,
  },
  eodyn_layers: [],
  layers: [],
  page: {
    home_hide: true,
    home_blocked: true,
    star_hide: true,
    star_blocked: true,
    download_hide: true,
    download_blocked: true,
    filter_hide: true,
    filter_blocked: true,
    setting_hide: true,
    setting_blocked: true,
    camera_hide: true,
    camera_blocked: true,
    chart_hide: true,
    chart_blocked: true,
    eye_hide: true,
    eye_blocked: true,
    derive_hide: true,
    derive_blocked: true,
  }
};

interface WebConfigProps {
  onConfigLoaded: (config: Config) => void; // Callback pour transmettre la config à App
  isLogin: boolean; // État de connexion
}

const WebConfig: React.FC<WebConfigProps> = ({ onConfigLoaded, isLogin }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isFetching = useRef<boolean>(false);
  const retryCount = useRef<number>(0);
  const maxRetries = 3;
  
  useEffect(() => {
    const fetchConfig = async () => {
      isFetching.current = true;
      try {
        const token = localStorage.getItem("authToken");
        const username = localStorage.getItem("username");
        const response = await fetch(`/data/config-client/${username}/yaml`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-yaml",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const yamlText = await response.text();
        console.log("YAML config load: SUCCESS");
        const parsedConfig = yaml.load(yamlText) as Config;
  
        onConfigLoaded(parsedConfig);
        setError(null);
        setIsLoaded(true);
      } catch (err) {
        retryCount.current += 1;
        console.error(`Failed to fetch config (attempt ${retryCount.current}):`, err);

        if (retryCount.current < maxRetries) {
          console.warn(`Retrying in 2 seconds... (${retryCount.current}/${maxRetries})`);
          setTimeout(fetchConfig, 2000); // Attendre 2 secondes avant de réessayer
        } else {
          console.error("Max retries reached. Using defaultConfig.");
          setError("Failed to load configuration after multiple attempts. Using default settings.");
          onConfigLoaded(defaultConfig);
          setIsLoaded(true);
        }
      } finally {
        isFetching.current = false;
      }
    };
  
    if (isLogin && !isLoaded && !isFetching.current) {
      fetchConfig();
    }
  }, [isLogin, isLoaded, onConfigLoaded]);
  
  return (
    <div className="config">
      {!isLoaded && <h1>Loading Configuration...</h1>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
export default WebConfig;