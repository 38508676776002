import React from 'react';

interface IconButtonProps {
  iconPath: string; // Chemin de l'icône à utiliser
  altText: string;  // Texte alternatif pour l'accessibilité
  onClick?: () => void; // Optionnel : fonction à exécuter lors du clic sur le bouton
  blocked?: boolean; // Ajout d'un booléen pour désactiver le bouton
}

const IconButton: React.FC<IconButtonProps> = ({ iconPath, altText, onClick, blocked = false }) => {
  return (
    <button
      className={blocked ? "custom-button-off" : "custom-button"}
      onClick={!blocked ? onClick : undefined} // Désactiver le onClick si disabled = true
      disabled={blocked} // Empêche aussi l'interaction via HTML
    >
      <img src={`${process.env.PUBLIC_URL}/images/icon/${iconPath}`} className="svg-icon" alt={altText} />
    </button>
  );
};

export default IconButton;
