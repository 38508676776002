import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { Config, LayerConfig } from './types/Config';

interface LayerSelectorProps {
  config: Config | null; // Passe la configuration complète
  visibleLayers: string[]; // Layers actuellement affichés
  onLayerOrderChange: (newLayersOrder: LayerConfig[]) => void;
  onLayerClick: (layerId: string) => void;
}

const LayerSelector: React.FC<LayerSelectorProps> = ({
  config,
  visibleLayers,
  onLayerOrderChange,
  onLayerClick,
}) => {
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
  const [layers, setLayers] = useState<LayerConfig[]>([]); // État pour stocker les couches triées
  const initializedLayersRef = useRef<Set<string>>(new Set()); // Garde une trace des couches déjà initialisées

  // Charger les couches et les trier par ordre
  useEffect(() => {
    if (config) {
      const sortedLayers = config.layers
        ?.map((layer) => ({ ...layer, order: layer.order ?? 0 }))
        .sort((a, b) => a.order - b.order);
      setLayers(sortedLayers || []);
    }
  }, [config]);

  // Déclenche des clics simulés pour les couches où `hide = false`
  useEffect(() => {
    layers.forEach((layer) => {
      if (!layer.hide && !initializedLayersRef.current.has(layer.id)) {
        initializedLayersRef.current.add(layer.id); // Marque la couche comme initialisée
        onLayerClick(layer.id); // Notifie le parent
        setSelectedLayers((prev) => [...prev, layer.id]); // Met à jour l'état local
      }
    });
  }, [layers, onLayerClick]);

  const handleLayerClick = (layerId: string) => {
    if (visibleLayers.includes(layerId)) {
      console.log(`Layer "${layerId}" est déjà affiché. Sélection ignorée.`);
      return;
    }

    const newSelectedLayers = selectedLayers.includes(layerId)
      ? selectedLayers.filter((id) => id !== layerId) // Désélectionner
      : [...selectedLayers, layerId]; // Sélectionner

    setSelectedLayers(newSelectedLayers);
    onLayerClick(layerId);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.index !== destination.index) {
      const reorderedLayers = Array.from(layers);
      const [movedLayer] = reorderedLayers.splice(source.index, 1);
      reorderedLayers.splice(destination.index, 0, movedLayer);

      setLayers(reorderedLayers); // Met à jour localement
      onLayerOrderChange(reorderedLayers); // Notifie le parent
    }
  };

  if (!config) {
    return <div>Loading layers...</div>; // Afficher un message de chargement si `config` n'est pas disponible
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="layers">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {layers.map((layer, index) => (
              <Draggable key={layer.id} draggableId={String(layer.id)} index={index}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`additional-layer-option ${
                      selectedLayers.includes(layer.id) ? 'selected' : ''
                    }`}
                    onClick={() => handleLayerClick(layer.id)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icon/layers.png`}
                      alt={layer.name}
                      className="handle"
                    />
                    <span>{layer.name}</span>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LayerSelector;
