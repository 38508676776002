import React, { useState } from 'react';

export interface SatelliteOptionsInterface {
    id: string;
    name: string;
    url: string;
    subdomains: string[];
}

const layers = [
    { id: "0", name: "Satellite", url: "https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}", subdomains: [] },
    { id: "1", name: "Ocean", url: "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}", subdomains: [] },
    { id: "2", name: "Black", url: "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}", subdomains: ['a', 'b', 'c'] },
    { id: "3", name: "Openstreetmap", url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", subdomains: ['a', 'b', 'c'] },
];

const SatelliteOptions: React.FC<{ onLayerClick: (layer: SatelliteOptionsInterface) => void }> = ({ onLayerClick }) => {
    const [selectedLayerName, setSelectedLayerName] = useState<string>(layers[0].name); // Default to the first layer

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedName = event.target.value;
        const selectedLayer = layers.find(layer => layer.name === selectedName);
        if (selectedLayer) {
            setSelectedLayerName(selectedLayer.name); // Update the selected layer name in state
            onLayerClick(selectedLayer); // Notify the parent of the change
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            const selectedLayer = layers.find(layer => layer.name === selectedLayerName);
            if (selectedLayer) {
                onLayerClick(selectedLayer); // Notify the parent of the change
            }
        }
    };

    return (
        <div className="baselayer-option-select">
            <select
                id="satellite-options"
                value={selectedLayerName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            >
                {layers.map(layer => (
                    <option className='satellite-options-layer' key={layer.id} value={layer.name}>
                        {layer.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SatelliteOptions;
