import React, { useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import '../../css/Login.css';

let keycloak: Keycloak.KeycloakInstance | null = null;

const initOptions = {
  url: "https://keycloak.eodyn.com", //process.env.REACT_APP_KEYCLOAK_URL  as string,
  realm: "master", //process.env.REACT_APP_KEYCLOAK_REALM  as string,
  clientId: "ocean-vibe", //process.env.REACT_APP_KEYCLOAK_CLIENT_ID  as string,
};

interface LoginProps {
  onLogin: (login: boolean) => void; // Callback pour transmettre la config à App
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (!keycloak) {
      keycloak = new Keycloak(initOptions);

      keycloak
        .init({ onLoad: "login-required" })
        .then((authenticated: any) => {
          if (authenticated) {
            setKeycloakInitialized(true);
            setUsername(keycloak?.tokenParsed?.preferred_username);
            localStorage.setItem('authToken', keycloak?.token as string);
            localStorage.setItem('username', keycloak?.tokenParsed?.preferred_username as string);
            onLogin(true);
          } else {
            console.log("Not authenticated");
          }
        })
        .catch((error: any) => {
          console.error("Failed to initialize Keycloak:", error);
        });
    } else {
      setKeycloakInitialized(true);
      setUsername(keycloak.tokenParsed?.preferred_username);
    }

    // Rafraîchir le token à intervalle régulier
    const refreshInterval = setInterval(() => {
      if (keycloak) {
        keycloak
          .updateToken(60) // Met à jour le token s'il expire dans les 60 secondes
          .then((refreshed: boolean) => {
            if (refreshed && keycloak) {
              console.log("Token was successfully refreshed");
              localStorage.setItem('authToken', keycloak.token as string);
            } else {
              console.warn("Token is still valid");
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            keycloak?.logout(); // Déconnexion si le rafraîchissement échoue
          });
      }
    }, 60000); // Intervalle de 60 secondes

    return () => clearInterval(refreshInterval); // Nettoyage lors du démontage du composant
  }, []);

  if (!keycloakInitialized) {
    return null; // Rien à afficher tant que Keycloak n'est pas initialisé
  }

  return (
    <div className="login-container">
      <div
        className="avatar"
        onClick={toggleDropdown}
        title="Profile menu"
      >
        {username ? username[0].toUpperCase() : "?"}
      </div>
      <span className="username" onClick={toggleDropdown}>
        {username || "Loading..."}
      </span>
      {dropdownVisible && (
        <div className="dropdown-menu">
          <p className="menu-item">
            <strong>{username || "Guest"}</strong>
          </p>
          <hr className="divider" />
          <button
            className="menu-item"
            onClick={() => {
              keycloak?.logout();
            }}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
