import React from 'react';
import '../css/LoadingPage.css';

type LoadingPageProps = {
  isVisible: boolean; // Contrôle la visibilité de la page de chargement
  backgroundImage: string; // URL de l'image de fond
};

const LoadingPage: React.FC<LoadingPageProps> = ({ isVisible, backgroundImage }) => {
  if (!isVisible) return null; // Si isVisible est false, on ne retourne rien

  return (
    <div
      className="loading-page"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingPage;
