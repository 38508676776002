import React, { useEffect, useState } from "react";
import { Config, LayerConfig } from "../types/Config";

import { LayerConfigWMS } from '../types/LayerConfigWMS';
import { LayerConfigEOdyn } from '../types/LayerConfigEOdyn';

export function getConfigWMSLayer(id: string, config: Config): LayerConfig | null {
    var layer_res: LayerConfig | null = null;
    config.layers.forEach((layer: LayerConfig) => {
        if (layer.id === id){
            layer_res = layer
        }
    });
    return layer_res
}

export function getConfigEOdynLayer(id: string, config: Config): LayerConfig | null {
    var layer_res: LayerConfig | null = null;
    config.eodyn_layers.forEach((layer: LayerConfig) => {
        if (layer.id === id){
            layer_res = layer
        }
    });
    return layer_res
}

//export function getLayerConfig(id: string, config: Config): LayerConfig | null {
//    var layer_res: LayerConfig | null = null;
//    config.layers_config.forEach((layer: LayerConfig) => {
//        if (layer.id === id){
//            layer_res = layer
//        }
//    });
//    return layer_res
//}

export function getRangeLayer(layer: LayerConfig, config: Config): Date[] | null {
    if (!layer.range || layer.range.length === 0 || layer.range.length > 2) return null;
  
    const parseDateInput = (input: string | undefined): Date => {
      if (!input || input.trim() === "") return new Date();
  
      if (input.startsWith("date")) {
        const now = new Date();
  
        // Exact: "date"
        if (input === "date") return now;
  
        // Ex: "date-1D", "date+4H"
        const match = input.match(/date([+-])(\d+)([YMDHms])/);
        if (match) {
          const [, sign, amountStr, unit] = match;
          const amount = parseInt(amountStr, 10);
          const multiplier = sign === "+" ? 1 : -1;
  
          const date = new Date(now);
          switch (unit) {
            case "Y":
              date.setFullYear(date.getFullYear() + multiplier * amount);
              break;
            case "M":
              date.setMonth(date.getMonth() + multiplier * amount);
              break;
            case "D":
              date.setDate(date.getDate() + multiplier * amount);
              break;
            case "H":
              date.setHours(date.getHours() + multiplier * amount);
              break;
            case "m":
              date.setMinutes(date.getMinutes() + multiplier * amount);
              break;
            case "s":
              date.setSeconds(date.getSeconds() + multiplier * amount);
              break;
            default:
              console.warn("Unsupported time unit:", unit);
          }
          return date;
        }
      }
  
      // Try normal parsing
      const parsed = new Date(input);
      if (!isNaN(parsed.getTime())) return parsed;
  
      console.warn("Invalid date format:", input);
      return new Date();
    };
  
    const minRange = parseDateInput(layer.range[0]);
    const maxRange = parseDateInput(layer.range[1]);
  
    return [minRange, maxRange];
  }
  

export function getConfigEOdynOrderList(config: Config): LayerConfig[] | null {
    if (!config || !config.eodyn_layers || config.eodyn_layers.length === 0) {
        return null; // Retourne null si la configuration ou les couches sont vides
      }

      const eodynLayers: LayerConfig[] = config.eodyn_layers;
    
      // Trie les couches en fonction de leur propriété `order`
      const sortedLayers = [...eodynLayers].sort((a, b) => {
        const orderA = a.order ?? 0; // Si `order` est null/undefined, utiliser 0
        const orderB = b.order ?? 0;
        return orderA - orderB; // Tri ascendant par `order`
      });
    
      return sortedLayers;
}

export function getConfigWMSOrderList(config: Config): LayerConfig[] | null {
    if (!config || !config.layers || config.layers.length === 0) {
        return null; // Retourne null si la configuration ou les couches sont vides
      }

      const layers: LayerConfig[] = config.layers;
    
      // Trie les couches en fonction de leur propriété `order`
      const sortedLayers = [...layers].sort((a, b) => {
        const orderA = a.order ?? 0; // Si `order` est null/undefined, utiliser 0
        const orderB = b.order ?? 0;
        return orderA - orderB; // Tri ascendant par `order`
      });
    
      return sortedLayers;
}