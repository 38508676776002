import * as maptalks from 'maptalks';
import { MetadataCache } from './types/MetadataCache';



//ajouter un system de hash deressource deja passé avec la date et id meme celle echoué pour eviter de les 
//refaire et de gagné du temps pour
//
//ou alors avoir un system de range de ressource dansle fichier config pour ne pas requeté des données non disponible



class LayerCache {
    private layerCache: { [key: string]: { layer: maptalks.Layer; metadata: MetadataCache } } = {};
    private failedKeys: string[] = [];

    // Générer une clé unique pour chaque combinaison de layerId, type et date
    private generateCacheKey(layerId: string, type: string, date: Date): string {
        return `${layerId}-${type}-${date.toISOString()}`;
    }

    // Vérifier si un layer existe déjà dans le cache
    layerExiste(layerId: string, type: string, date: Date): boolean {
        const cacheKey = this.generateCacheKey(layerId, type, date);
        return cacheKey in this.layerCache;
    }

    // Sauvegarder un layer, avec vérification s'il existe déjà
    saveLayer(layerId: string, type: string, date: Date, layer: maptalks.Layer) {
        if (layerId.includes("tmp_")) {
            console.warn(`Layer avec layerId: ${layerId}, type: ${type}, et date: ${date.toISOString()} ne peut pas être caché.`);
            return; // Ne pas ajouter le layer s'il est déjà présent
        }
        if (this.layerExiste(layerId, type, date)) {
            console.warn(`Layer avec layerId: ${layerId}, type: ${type}, et date: ${date.toISOString()} existe déjà.`);
            return; // Ne pas ajouter le layer s'il est déjà présent
        }
        const cacheKey = this.generateCacheKey(layerId, type, date);
        const metadata: MetadataCache = { layerId, type, date };
        this.layerCache[cacheKey] = { layer, metadata };
        console.log(`Layer sauvegardé dans le cache avec la clé: ${cacheKey}`);
    }

    // Récupérer un layer en utilisant layerId, type et date
    getLayer(layerId: string, type: string, date: Date): maptalks.Layer | null {
        const cacheKey = this.generateCacheKey(layerId, type, date);
        const cachedLayer = this.layerCache[cacheKey];
        if (cachedLayer) {
            console.log(`Layer trouvé dans le cache avec la clé: ${cacheKey}`);
            return cachedLayer.layer;
        } else {
            console.warn(`Layer non trouvé dans le cache pour la clé: ${cacheKey}`);
            //this.afficheCache()
            return null;
        }
    }

    // Supprimer un layer du cache en utilisant layerId, type et date
    removeLayer(layerId: string, type: string, date: Date) {
        const cacheKey = this.generateCacheKey(layerId, type, date);
        if (this.layerCache[cacheKey]) {
            delete this.layerCache[cacheKey];
            console.log(`Layer supprimé du cache avec la clé: ${cacheKey}`);
        } else {
            console.warn(`Layer non trouvé dans le cache pour la clé: ${cacheKey}`);
        }
    }

    // Nettoyer tous les layers du cache
    clearCache() {
        this.layerCache = {};
        console.log(`Tous les layers ont été supprimés du cache.`);
    }

    // Nettoyer les layers expirés
    //clearExpiredLayers(expirationTime: number) {
    //    const now = new Date();
    //    for (const [cacheKey, cachedLayer] of Object.entries(this.layerCache)) {
    //        if (now.getTime() - cachedLayer.metadata.date.getTime() > expirationTime) {
    //            this.removeLayer(cachedLayer.metadata.layerId, cachedLayer.metadata.type, cachedLayer.metadata.date);
    //        }
    //    }
    //}

    afficheCache() {
        console.log("Contenu du cache :");
        let count = 0;
        for (const [key, cachedLayer] of Object.entries(this.layerCache)) {
            count++;
            console.log(`Clé: ${key}`);
            //console.log(`  Layer ID: ${cachedLayer.metadata.layerId}`);
            //console.log(`  Type: ${cachedLayer.metadata.type}`);
            //console.log(`  Date: ${cachedLayer.metadata.date}`);
            //console.log(`  Layer:`, cachedLayer.layer);
        }
        console.log(`Total layers en cache: ${count}`);
    }

    isFailedRessource(layerId: string, type: string, date: Date){
        const cacheKey = this.generateCacheKey(layerId, type, date);
        return this.isFailed(cacheKey);
    }

    isFailed(cacheKey: string){
        return this.failedKeys.includes(cacheKey);
    }

    addFailedRessource(layerId: string, type: string, date: Date) {
        const cacheKey = this.generateCacheKey(layerId, type, date);
        if (!this.isFailed(cacheKey)){
            this.failedKeys.push(cacheKey)
        }
        console.log("Failed Ressource: ",this.failedKeys.length);
    }
}

export default LayerCache;