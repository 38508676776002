import React, { useState, useEffect, useRef } from 'react';
import { Config, LayerConfig } from './types/Config';

interface LayerSelectorEodynProps {
  config: Config | null; // Passez la configuration en tant que prop
  onLayerClick: (layerId: string) => void;
}

const LayerSelectorEodyn: React.FC<LayerSelectorEodynProps> = ({ config, onLayerClick }) => {
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
  const [eodynLayers, setEodynLayers] = useState<LayerConfig[]>([]);
  const initializedLayersRef = useRef<Set<string>>(new Set()); // Garde une trace des couches initialisées

  // Charger et trier les couches Eodyn
  useEffect(() => {
    if (config) {
      const sortedLayers = config.eodyn_layers
        ?.map((layer: LayerConfig) => ({ ...layer, order: layer.order ?? 0 }))
        .sort((a: LayerConfig, b: LayerConfig) => a.order - b.order);
      setEodynLayers(sortedLayers || []);
    }
  }, [config]);

  // Déclenche des clics simulés pour les couches où `hide = false`
  useEffect(() => {
    eodynLayers.forEach((layer) => {
      if (!layer.hide && !initializedLayersRef.current.has(layer.id)) {
        initializedLayersRef.current.add(layer.id); // Marque la couche comme initialisée
        onLayerClick(layer.id); // Notifie le parent
        setSelectedLayers((prev) => [...prev, layer.id]); // Met à jour l'état local
      }
    });
  }, [eodynLayers, onLayerClick]);

  const handleLayerClick = (layerId: string) => {
    const isSelected = selectedLayers.includes(layerId);
    const newSelectedLayers = isSelected
      ? selectedLayers.filter((id) => id !== layerId) // Désélectionner
      : [...selectedLayers, layerId]; // Sélectionner

    setSelectedLayers(newSelectedLayers);
    onLayerClick(layerId); // Déclenche une action dans le parent
  };

  if (!config) {
    return <div>Loading layers...</div>; // Affiche un message de chargement si la config n'est pas encore chargée
  }

  return (
    <div id="sortable-layers" className="layer">
      {eodynLayers.map((layer) => (
        <div
          key={layer.id}
          className={`additional-layer-option ${selectedLayers.includes(layer.id) ? 'selected' : ''}`}
          onClick={() => handleLayerClick(layer.id)}
        >
          <span>{layer.name}</span>
        </div>
      ))}
    </div>
  );
};

export default LayerSelectorEodyn;
